<template>
  <div>
    <!-- Chart and Data Section -->
    <vue-apex-charts
      type="area"
      height="168"
      :options="OverviewCampaignData.overviewChart.chartOptions"
      :series="OverviewCampaignData.overviewChart.series"
    />
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
import OverviewCampaignData from './OverviewCampaignData';
export default {
  name: 'OverviewCampaignChart',
  components: {
    VueApexCharts
  },
  data: () => ({
    title: 'OverviewCampaignChart',
    OverviewCampaignData: OverviewCampaignData
  })
};
</script>