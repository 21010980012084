<template>
  <div>
    <vue-apex-charts
      type="area"
      height="69px"
      :options="SalesratioChart.salesChart.chartOptions"
      :series="SalesratioChart.salesChart.series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import SalesratioChart from './SalesRatioData';

export default {
  name: 'SalesRatio',
  components: {
    VueApexCharts
  },
  data: () => ({
    title: 'SalesRatio',
    SalesratioChart: SalesratioChart
  })
};
</script>