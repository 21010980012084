<template>
  <div>
    <vue-apex-charts
      type="bar"
      height="70"
      :options="TotalVisitsChart.visitChart.chartOptions"
      :series="TotalVisitsChart.visitChart.series"
    />
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
import TotalVisitsChart from './TotalVisitsData';

export default {
  name: 'TotalVisits',
  components: {
    VueApexCharts
  },
  data: () => ({
    title: 'TotalVisits',
    TotalVisitsChart: TotalVisitsChart
  })
};
</script>